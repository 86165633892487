@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

body {
  background: #101213;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
}

.errorBox {
  margin-top: 50px;
  margin-bottom: 160px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.error-image {
  width: 50%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.error-text {
  font-size: 20px;
  color: #727272;
  font-weight: 500;
}

.error-button {
  background-color: #2db0fe;
  height: 40px;
  padding-right: 30px;
  padding-left: 30px;
  font-weight: 500;
  margin-top: 20px;
  border-radius: 100px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
  transition: 0.3s all ease-in-out;
}

@media (max-width: 1000px) {
  .error-image {
    width: 100%;
  }

  .error-text {
    font-size: 14px;
  }
}
