@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

body {
  background: #101213;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
}

.legal-container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.head {
  text-align: center;
}

.head .title {
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 0;
}

.subtitle {
  margin-top: 10px;
  color: #727272;
}

.title {
  font-size: 25px;
  font-weight: 700;
  margin-top: 50px;
}

p {
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 1000px) {
  .legal-container {
    width: 90%;
    margin-top: 50px;
  }

  .head .title {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .legal-container {
    width: 85%;
    margin-top: 50px;
  }
}

.search-bar {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 300px;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.search-bar input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.permalink-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.link-button {
  color: #ffffff;
}

.link-button:hover {
  color: #2db0fe;
}

.highlight {
  background-color: rgb(163, 163, 0);
}

.indented-bullet {
  margin-left: 30px;
}
