.info-box {
  padding: 15px;
  border-radius: 4px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: fit-content;
  height: fit-content;
}

.error {
  background-color: red;
}

.warning {
  background-color: #fd7e14;
}

.success {
  background-color: #28a745;
}

.info-box-icon {
  padding-right: 30px;
}

.info-close-btn {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
