.modal {
  max-width: 30rem;
  width: 100%;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: hsla(0, 0%, 0%, 0.1) 0 0 1rem;
  background: #1a1c1e;
  color: white;
}

.modal::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.modal-close-btn {
  font-size: 0.75em;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.modal-title {
  text-align: center;
}
