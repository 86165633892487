.out-of-stock {
  margin-left: auto;
  color: red;
  font-weight: bold;
  padding-left: 10px;
}

.dropdown-option.disabled {
  cursor: not-allowed;
  color: red;
}
