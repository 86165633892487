.container {
  text-align: center;
}

.section-heading {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 40px;
  text-decoration: underline;
}

.section-subheading {
  margin-top: 30px;
  font-size: 0.9rem;
  color: gray;
}

.flex-feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
  text-align: left;
}

.feature {
  width: calc(33.333% - 28px);
  max-width: calc(33.333% - 28px);
  background-color: #333;
  margin: 7px 14px;
  padding: 12px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
  color: silver;
  display: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.feature i {
  font-size: 24px;
  margin-right: 10px;
  color: #2cb1f8;
}

.feature.mc i {
  background: rgb(90, 155, 49);
  background: linear-gradient(
    135deg,
    rgba(90, 155, 49, 1) 15%,
    rgba(151, 199, 103, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature.te i {
  background: rgb(87, 221, 85);
  background: linear-gradient(
    135deg,
    rgba(87, 221, 85, 1) 15%,
    rgba(8, 87, 0, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature h3 {
  font-size: 1rem;
  flex-grow: 1;
  margin: 0;
}

@media screen and (max-width: 992px) {
  .feature {
    width: calc(50% - 28px);
    max-width: calc(50% - 28px);
  }
}

@media screen and (max-width: 576px) {
  .feature {
    width: calc(100% - 28px);
    max-width: calc(100% - 28px);
  }
}

.item-font {
  font-size: 1rem;
  flex-grow: 1;
}
