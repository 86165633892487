@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

body {
  background: #101213;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
}

.locations {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}

.subtitle {
  color: #727272;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 500;
}

.title {
  font-size: 40px;
  font-weight: 600;
}

.world-map {
  margin-top: 70px;
  position: relative;
}
.image-div {
  position: relative;
}
.world-image {
  display: block;
  width: 100%;
}

.locations-grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  gap: 20px;
  margin-top: 30px;
}

.location-div {
  background-color: #1a1c1e;
  border-radius: 10px;
  border: 2px solid #222426;
  height: 90px;
}

.flex-location {
  display: flex;
}

.side-flag {
  max-width: 30%;
  flex: 30%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side {
  flex: 70%;
  max-width: 70%;
  height: 90px;
  align-items: center;
  display: flex;
  text-align: left;
}

.country-flag {
  width: 64px;
  height: 48px;
  object-fit: contain;
}

.location-name {
  font-weight: 500;
}

.location-network {
  font-size: 14px;
  color: #727272;
}

.country-name {
  color: #d1d5db;
  font-size: 14px;
  font-weight: 500;
}

.pin {
  background-color: #2db0fe;
  border-radius: 50%;
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 10px;
  z-index: 10;
  animation: move 4s infinite;
}

@keyframes move {
  50% {
    box-shadow: 0 0 0 5px rgba(45, 176, 254, 0.28);
  }
}

@media (max-width: 1300px) {
  .locations-grid {
    display: block;
    text-align: center;
  }

  .location-div {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}

@media (max-width: 700px) {
  .location-div {
    width: 100%;
  }

  .country-flag {
    width: 54px;
    height: 38px;
  }

  .location-name {
    font-size: 14px;
  }

  .pin {
    width: 5px;
    height: 5px;
  }
}
