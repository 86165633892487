html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
  width: 100%;
}

.branding-section {
  margin-top: 20px;
  padding: 20px;
  border: 2px solid #1a1c1e;
  border-radius: 10px;
  width: 100%;
}

.banner-section h2 {
  font-size: 24px;
  margin-bottom: 16px;
}

.banner-section p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.banner-example {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin-top: 16px;
}

.banner-example h3 {
  font-size: 18px;
  margin-bottom: 8px;
}

.banner-example img {
  max-width: 100%;
  height: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.align-center {
  flex-wrap: wrap;
  align-content: center;
  text-align: left;
  justify-content: center;
}

.square-logo-section h2 {
  font-size: 24px;
  margin-bottom: 16px;
}

.logo-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.logo-item {
  flex: 1;
  max-width: calc(25% - 20px);
  margin-bottom: 20px;
}

.logo-item img {
  max-width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.colors-section h2 {
  font-size: 24px;
  margin-bottom: 16px;
}

.color-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.color-item {
  flex: 1;
  max-width: calc(33.33% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-box {
  width: 100px;
  height: 100px;
  margin: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.color-info {
  margin-top: 8px;
  text-align: center;
}

.color-name {
  font-weight: bold;
}

.color-hex {
  color: #6c757d;
}

@media screen and (max-width: 768px) {
  .branding-section {
    margin-top: 20px;
    padding: 20px;
    border: 2px solid #1a1c1e;
    border-radius: 10px;
    width: auto;
  }

  .color-list {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .color-box {
    width: 100px;
    height: 100px;
    margin: 10px;
    border-radius: 50%;
    border: 2px solid #fff;
  }
  .color-item {
    flex: 1;
    max-width: calc(33.33% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
  }
}
