.table-of-contents {
  margin-top: 20px;
  border-radius: 5px;
  padding: 5px 5px 5px 40px;
  background-color: #1a1c1e;
}

.table-of-contents h2 {
  margin-top: 0;
  font-size: 1.2em;
}

.table-of-contents ul {
  list-style-type: none;
}

.table-of-contents li {
  margin: 5px 0;
}

.table-of-contents a {
  text-decoration: none;
  color: #e2ebf4;
}

.table-of-contents .toc-level-2 {
  margin-left: 10px;
}

.table-of-contents .toc-level-3 {
  margin-left: 20px;
}

@media only screen and (min-width: 1600px) {
  .table-of-contents {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-top: 20px;
    border-radius: 5px;
    padding: 5px 5px 5px 30px;
    background-color: #1a1c1e;
    z-index: 999;
    width: 11.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .table-of-contents {
    margin-top: 20px;
    border-radius: 5px;
    padding: 5px 5px 5px 40px;
    background-color: #1a1c1e;
  }

  .table-of-contents h2 {
    margin-top: 0;
    font-size: 1.2em;
  }

  .table-of-contents ul {
    list-style-type: none;
  }

  .table-of-contents li {
    margin: 5px 0;
  }

  .table-of-contents a {
    text-decoration: none;
    color: #e2ebf4;
  }

  .table-of-contents .toc-level-2 {
    margin-left: 10px;
  }

  .table-of-contents .toc-level-3 {
    margin-left: 20px;
  }
}
