.product-detail {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.product-info {
  align-items: center;
  display: flex;
}

.product-info h1 {
  font-size: 24px;
}

.product-info h2 {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
  max-width: 500px;
}

.img-detail {
  border-radius: 15px;
  width: 100%;
  display: block;
  min-width: 500px;
}

img {
  overflow-clip-margin: content-box !important;
  overflow: clip !important;
}
.carousel {
  overflow: hidden;
  border-radius: 15px;
}
.product-features {
  display: block;
}
.visually-hidden {
  display: none;
}

.feature-product-detail {
  width: 680px;
  white-space: pre;
}

@media screen and (max-width: 1810px) {
  .products {
    width: 85%;
  }
  .product-detail {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  .product-info {
    align-items: center;
    display: flex;
  }

  .product-info h1 {
    font-size: 24px;
  }

  .product-info h2 {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
    max-width: 500px;
  }

  .img-detail {
    border-radius: 15px;
    width: 100%;
    min-width: 360px;
  }

  img {
    overflow-clip-margin: content-box !important;
    overflow: clip !important;
  }
  .carousel {
    overflow: hidden;
    border-radius: 15px;
  }
  .product-features {
    display: block;
  }
  .visually-hidden {
    display: none;
  }

  .feature h3 {
    font-size: 0.8rem;
    white-space: pre;
  }
  .feature-product-detail {
    width: 570px;
    white-space: pre;
  }
}

@media screen and (max-width: 1181px) and (min-width: 822px) {
  .feature-product-detail {
    max-width: none;
    width: 30%;
  }
  .product-detail {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    justify-content: space-evenly;
  }

  .product-detail-featurelist {
    justify-content: center;
  }

  .product-info h1 {
    font-size: 26px;
  }

  .product-info h2 {
    margin-top: 10px;
    font-size: 18px;
    color: #666;
    max-width: 800px;
  }
  .product-info {
    padding: 0;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .img-detail {
    height: auto;
    width: 100% !important;
    position: relative;
    min-width: auto;
  }
}

@media only screen and (max-width: 821px) {
  .feature-product-detail {
    max-width: none;
    width: 100%;
  }
  .product-detail {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    justify-content: space-evenly;
  }

  .product-detail-featurelist {
    justify-content: center;
  }

  .product-info {
    padding: 0;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .img-detail {
    height: auto;
    width: 100% !important;
    position: relative;
    min-width: auto;
  }
}
