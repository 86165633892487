::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #101213;
}

::-webkit-scrollbar-thumb {
  background: #222426;
}

::-webkit-scrollbar-thumb:hover {
  background: #1a1c1e;
}

.header {
  width: 70%;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: flex;
}

.flex {
  flex: auto;
}

.logo {
  width: 100px;
  cursor: pointer;
}

.logo-div {
  height: 70px;
  display: flex;
  align-items: center;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.btns {
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: flex-end;
}

.section {
  margin: 20px;
  color: #d1d5db;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.section:hover {
  transition: 0.3s;
  color: white;
}

.btn-header {
  padding: 10px 30px 10px 30px;
  background-color: #2db0fe;
  border: none;
  border-radius: 100px;
  color: black;
  font-weight: 500;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s all ease-in;
}

.btn-header:hover {
  transition: 0.2s;
  background-color: #24aae0;
}

.company-dropdown,
.login-box {
  background-color: #1a1c1e;
  border-radius: 10px;
  width: 100%;
  padding-top: 10px;
}

.section-dropdown {
  margin-top: 10px;
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.section-dropdown:hover {
  transition: 0.3s;
  background-color: #141617;
}

.icon-dropdown {
  color: #2db0fe;
}

.section-div-dropdown {
  flex: 80%;
}

.section-div-dropdown.login {
  display: flex;
  align-items: center; /* Align vertical */
  text-align: left;
}

.center-dropdown {
  flex: 20%;
}

.section-name-dropdown {
  font-weight: 500;
  font-size: 18px;
  color: white;
}

.section-name-dropdown.login {
  font-weight: 500;
  font-size: 19px;
  color: white;
}

.section-description-dropdown {
  color: #727272;
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
}

.policies {
  background-color: #141617;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.policy-page {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #d1d5db;
  margin-left: 20px;
  margin-top: 5px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.policy-page:hover {
  color: white;
  transition: 0.3s;
}

.tippy-box[data-theme~="company"] {
  width: 300%;
  background-color: transparent;
  margin-left: -70px;
  margin-top: 10px;
}

.tippy-arrow {
  display: none;
}

* {
  text-decoration: none;
}

.padding-down {
  padding-bottom: 20px;
}

.login-box {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mobile {
  text-align: center;
  display: none;
}

.header-mobile,
.logo-box,
.mobile-rel,
.header-box-bottom {
  display: none;
}
.header-mobile.active {
  display: inline;
}

.fa-circle {
  color: #101213;
}
.icon-padding {
  padding-right: 55px;
}

.fa-2x {
  font-size: 1.8em;
}

@media (max-width: 1300px) {
  .aos-animate {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;

    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;

    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }

  .links {
    display: none;
  }

  .btns {
    display: none;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile {
    text-align: center;
    display: block;
    z-index: 35;
  }

  .bars {
    cursor: pointer;
    color: white;
    transition: 0.3s all ease-in-out;
  }

  .bars:hover {
    transition: 0.3s;
    color: #727272;
  }

  .header-mobile {
    position: absolute;
    z-index: 33;
    top: 0;
    width: 100%;
    background-color: #1a1c1e;
  }

  .logo-mobile {
    width: 30%;
    max-width: 150px;
    margin-left: 50px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .logo-box {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .mobile-rel {
    margin-left: 50px;
    color: white;
    font-weight: 500;
    background-color: #222426;
    border-radius: 100px;
    padding: 10px 0 10px 20px;
    width: 70%;
    display: block;
    margin-top: 10px;
  }

  .header-box-bottom {
    background-color: #141617;
    padding-bottom: 10px;
    margin-top: 20px;
    display: block;
  }

  .mobile-sec {
    display: block;
    font-size: 16px;
    color: #d1d5db;
    font-weight: 500;
    margin-left: 50px;
    margin-top: 5px;
  }

  .top {
    padding-top: 20px;
  }

  .bottom {
    padding-bottom: 20px;
  }

  .company-name-header {
    margin-left: 50px;
    font-size: 14px;
    color: #727272;
    font-weight: 500;
    padding-top: 15px;
  }

  .line-header {
    background-color: #222426;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 2px;
    margin-top: 20px;
  }
}
