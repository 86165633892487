@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

body {
  background: #101213;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
}

.contact {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: #1a1c1e;
  display: flex;
  border-radius: 10px;
  border: 2px solid #222426;
}

.left-contact {
  flex: 50%;
  max-width: 50%;
  padding: 50px;
}

.right-contact {
  flex: 50%;
  padding: 50px;
  max-width: 50%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #2db0fe;
  display: flex;
  align-items: center;
}

.title-right {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.envelope {
  margin-top: 10px;
  color: #d1d5db;
  width: 10px;
  height: 10px;
}

.link-right {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  transition: 0.3s all ease-in-out;
  margin-left: 20px;
}

.link-right:hover {
  transition: 0.3s;
  color: white;
}

.contact_title {
  background: linear-gradient(135deg, #2db0fe, #0f80ad);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 600;
}

.input-label {
  font-size: 16px;
  color: white;
  font-weight: 500;
  margin-top: 20px;
}

.input-contact {
  box-sizing: border-box;
  padding-left: 15px;
  height: 30px;
  width: 80%;
  margin-top: 10px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  background-color: #222426;
}

.textarea-contact {
  box-sizing: border-box;
  padding-left: 15px;
  height: 150px;
  width: 80%;
  margin-top: 10px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  background-color: #222426;
  resize: vertical;
  padding-top: 15px;
}

.btn-send {
  width: 80%;
  background-color: #2db0fe;
  border: none;
  border-radius: 100px;
  color: white;
  height: 30px;
  font-weight: 500;
  margin-top: 40px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s all ease-in;
}
.btn-cancel {
  width: 80%;
  background-color: #fc3434;
  border: none;
  border-radius: 100px;
  color: white;
  height: 30px;
  font-weight: 500;
  margin-top: 40px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s all ease-in;
}
