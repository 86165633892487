@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

body {
  background: #101213;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
}

.products {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}

.subtitle {
  color: #727272;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 500;
}

.title {
  font-size: 40px;
  font-weight: 600;
}

.product {
  background-size: cover;
  width: 30%;
  margin: 0 auto;
  height: 235px;
  border-radius: 10px;
  text-align: left;
  padding: 30px;
  box-sizing: border-box;
}

.products-boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 50px;
}

.product-name {
  font-size: 20px;
  font-weight: 600;
  margin-top: 90px;
}

.product-price {
  color: #2db0fe;
  font-weight: 500;
  font-size: 18px;
}

.forever {
  font-size: 14px;
  color: #727272;
}

.btn-order {
  width: 100%;
  background-color: rgba(45, 176, 254, 0.7);
  border: none;
  border-radius: 100px;
  color: white;
  height: 30px;
  font-weight: 500;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s all ease-in;
}

.btn-order:hover {
  transition: 0.2s;
  background-color: rgba(45, 176, 254, 0.9);
}

.btn-order.error:hover,
.btn-order.warning:hover {
  cursor: not-allowed;
  background-color: red !important;
}

.product-box {
  width: 80%;
  background-color: #1a1c1e;
  border-radius: 10px;
  border: 2px solid #222426;
  text-align: left;
  padding: 30px;
  display: flex;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
}

.product-box-features {
  width: 80%;
  background-color: #1a1c1e;
  border-radius: 10px;
  border: 2px solid #222426;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: center;
  box-sizing: border-box;
  margin-top: 30px;
  padding-bottom: 30px;
}

.product-box-features-container {
  padding: 30px;
}

.product-box-second {
  width: 80%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.product-side {
  flex: 70%;
  max-width: 70%;
}

.product-side-right {
  flex: 30%;
  max-width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controler {
  margin-left: 10px;
  margin-top: 20px;
  color: #d1d5db;
}

.product-name-game {
  font-size: 14px;
  font-weight: 800;
  margin-left: 10px;
  color: #d1d5db;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
}

.product-title {
  font-size: 36px;
  background: linear-gradient(135deg, #2db0fe, #0f80ad);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 10px;
  margin-top: 10px;
  font-weight: bold;
}

.minecraft-text {
  background: rgb(90, 155, 49);
  background: linear-gradient(
    135deg,
    rgba(90, 155, 49, 1) 15%,
    rgba(151, 199, 103, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.minecraft {
  background: rgb(90, 155, 49);
  background: linear-gradient(
    135deg,
    rgba(90, 155, 49, 1) 15%,
    rgba(151, 199, 103, 1) 100%
  );
}

.terraria-text {
  background: rgb(87, 221, 85);
  background: linear-gradient(
    135deg,
    rgba(87, 221, 85, 1) 15%,
    rgba(8, 87, 0, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.terraria {
  background: rgb(87, 221, 85);
  background: linear-gradient(
    135deg,
    rgba(87, 221, 85, 1) 15%,
    rgba(8, 87, 0, 1) 100%
  );
}

.product-image {
  height: 200px;
  filter: drop-shadow(10px 10px 5px #222426);
  animation: bounce2 4s ease infinite;
}

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.product-description {
  color: #727272;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  width: 80%;
  margin-top: 10px;
}

.btn-product-box {
  padding: 15px 70px 15px 70px;
  background-color: #2db0fe;
  border: none;
  border-radius: 100px;
  color: white;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 30px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s all ease-in;
}

.product-game-box {
  background-color: #1a1c1e;
  width: 30%;
  margin: 0 auto;
  border-radius: 10px;
  text-align: left;
  padding: 30px;
  box-sizing: border-box;
  border: 2px solid #222426;
}

.plan-svg {
  color: white;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.product-plan-name {
  color: white;
  font-weight: 500;
  margin-left: 10px;
}

.plan-gb {
  color: white;
  font-weight: 600;
  font-size: 28px;
  margin-top: 10px;
}

.plan-feature {
  color: #d1d5db;
  font-size: 17px;
  margin-top: 8px;
  font-weight: 500;
}

.plan-price {
  margin-top: 30px;
  font-size: 28px;
  font-weight: 600;
  color: #24aae0;
}

.monthly {
  font-size: 14px;
}

.btn-order {
  background-color: #24aae0;
  height: 40px;
  width: 100%;
  font-weight: 500;
  margin-top: 20px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
}

@media (max-width: 1380px) {
  .products {
    width: 85%;
  }

  .product-box {
    width: 100%;
  }

  .product-box-second {
    width: 100%;
  }

  .product-box-features {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .products {
    margin-top: 50px;
  }

  .products-boxes {
    display: block;
  }

  .product {
    margin-top: 30px;
    width: 100%;
  }

  .products-boxes {
    display: block;
  }

  .product-game-box {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
    margin-top: 30px;
  }

  .product-box-features {
    width: 100%;
  }

  .product-box {
    display: block;
  }

  .product-side {
    max-width: 100%;
  }

  .product-side-right {
    display: none;
  }

  .product-name-game {
    font-size: 12px;
  }

  .product-title {
    font-size: 24px;
    margin-top: 20px;
    margin-left: 0;
  }

  .controler {
    margin-left: 0;
  }

  .product-description {
    font-size: 14px;
    margin-left: 0;
  }

  .btn-product-box {
    width: 100%;
    padding: 0;
    height: 40px;
    margin-left: 0;
  }
}
