@media only screen and (min-width: 390px) and (max-width: 768px) {
  .timeline ul {
    padding: 50px 0;
  }

  .timeline ul li {
    position: relative;
    list-style-type: none;
    width: 2px;
    background-color: #2db0fe;
    padding-top: 50px;
  }
  .timeline ul li::after {
    background-color: #2db0fe;
    border: 2px #2db0fe;
    border-radius: 15px;
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  .timeline ul li div {
    position: relative;
    width: 330px;
    padding: 15px;
    border-radius: 5px;
    bottom: 0;
    background: #1a1c1e;
  }

  .timeline ul li:nth-child(odd) div {
    left: 20px;
  }

  .timeline ul li:nth-child(even) div {
    left: 20px;
  }

  .timeline-content {
    width: 100%;
  }
  .timeline time {
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
  }

  .timeline ul li div {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  .timeline ul li:nth-child(even) li:nth-child(odd) div {
    transform: translate3d(-200px, 0, 0);
  }

  .timeline ul li.show div {
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}

@media only screen and (max-width: 390px) {
  .timeline ul {
    padding: 50px 0;
  }

  .timeline ul li {
    position: relative;
    list-style-type: none;
    width: 2px;
    background-color: #2db0fe;
    padding-top: 50px;
  }
  .timeline ul li::after {
    background-color: #2db0fe;
    border: 2px #2db0fe;
    border-radius: 15px;
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  .timeline ul li div {
    position: relative;
    width: 290px;
    padding: 10px;
    border-radius: 5px;
    bottom: 0;
    background: #1a1c1e;
  }

  .timeline ul li:nth-child(odd) div {
    left: 20px;
  }

  .timeline ul li:nth-child(even) div {
    left: 20px;
  }

  .timeline-content {
    width: 100%;
  }
  .timeline time {
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
  }

  .timeline ul li div {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  .timeline ul li:nth-child(even) li:nth-child(odd) div {
    transform: translate(-200px, 0, 0);
  }

  .timeline ul li.show div {
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}

@media only screen and (min-width: 769px) {
  .timeline ul {
    padding: 50px 0;
  }

  .timeline ul li {
    position: relative;
    list-style-type: none;
    width: 6px;
    background-color: #2db0fe;
    margin: 0 auto;
    padding-top: 50px;
  }

  .timeline ul li::after {
    background-color: #2db0fe;
    border: 2px #2db0fe;
    border-radius: 15px;
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  .timeline ul li div {
    position: relative;
    width: 300px;
    padding: 15px;
    border-radius: 5px;
    bottom: 0;
    background: #1a1c1e;
  }

  .timeline ul li:nth-child(odd) div {
    left: 45px;
  }

  .timeline ul li:nth-child(even) div {
    left: -345px;
    box-sizing: border-box;
  }

  .timeline time {
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
  }

  .timeline ul li div {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  .timeline ul li:nth-child(odd) div {
    transform: translate3d(200px, 0, 0);
  }

  .timeline ul li:nth-child(even) div {
    transform: translate3d(-200px, 0, 0);
  }

  .timeline ul li.show div {
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}

@media only screen and (min-width: 1000px) {
  .timeline ul {
    padding: 50px 0;
  }

  .timeline ul li {
    position: relative;
    list-style-type: none;
    width: 6px;
    background-color: #2db0fe;
    margin: 0 auto;
    padding-top: 50px;
  }

  .timeline ul li::after {
    background-color: #2db0fe;
    border: 2px #2db0fe;
    border-radius: 15px;
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  .timeline ul li div {
    position: relative;
    width: 400px;
    padding: 15px;
    border-radius: 5px;
    bottom: 0;
    background: #1a1c1e;
  }

  .timeline ul li:nth-child(odd) div {
    left: 45px;
  }

  .timeline ul li:nth-child(even) div {
    left: -445px;
    box-sizing: border-box;
  }

  .timeline time {
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
  }

  .timeline ul li div {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  .timeline ul li:nth-child(odd) div {
    transform: translate3d(200px, 0, 0);
  }

  .timeline ul li:nth-child(even) div {
    transform: translate3d(-200px, 0, 0);
  }

  .timeline ul li.show div {
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}
