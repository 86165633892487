.form-row + .form-row {
  margin-top: 1rem;
}

.form-row {
  padding: 1rem;
}

.form-row label,
.form-row input,
.form-row select,
.form-row button {
  width: 100%;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  padding: 20px 0 0 0;
}

.selected-option {
  display: flex;
  align-items: center;
  background-color: #222426;
  padding: 8px 0.5rem;
  border: 1px solid #222426;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-options {
  list-style: none;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: #222426;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-option {
  padding: 0.5rem;
  cursor: pointer;
}

.flag-icon {
  width: 24px;
  height: 16px;
  margin-right: 8px;
}

.fa-chevron-down,
.fa-chevron-up {
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
}

.custom-dropdown .fas.fa-chevron-up {
  transform: rotate(180deg);
}

.ordermodal-warning-spacing {
  justify-content: space-between !important;
}
