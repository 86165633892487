.location-container {
  margin-bottom: 20px;
  padding: 10px;
}

.location-details {
  border: 2px solid #1a1c1e;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.flag-container {
  margin-right: 10px;
}

.flag-image {
  width: 30px;
  height: auto;
}

.features i {
  color: #2cb1f8;
}

.location-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.hardware-info {
  margin: 0;
}

.location-details:hover .hardware-container,
.location-details:focus .hardware-container {
  display: block;
}

.location-details i {
  font-size: 16px;
  color: #2cb1f8;
  margin-left: auto;
  padding-left: 5px;
  transition: transform 0.2s ease;
}
.hardware-container {
  display: none;
}
.hardware-container.active {
  display: block;
}
.hardware-card-container {
  display: flex;
  flex-wrap: wrap;
}
.hardware-card {
  flex: 35.333%;
  max-width: 35.333%;
  height: auto;
  border: 2px solid #1a1c1e;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
  text-align: left;
  transition: 0.3s all ease-in-out;
  margin-right: 20px;
  margin-top: 20px;
}

.caret-icon {
  font-size: 16px;
  color: #2cb1f8;
  margin-left: 10px;
  transition: transform 0.2s ease;
}

.location-container.active .caret-icon.rotate {
  transform: rotate(180deg);
}

.pillbox {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  font-size: small;
}

.title-tag {
  margin-left: 20px;
}

.p-info {
  background-color: #2cb1f8;
}

.p-warning {
  background-color: #fd7e14;
}

.tags {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
}

.t-info {
  color: #2cb1f8;
}
@media screen and (max-width: 1370px) {
  .title-tag {
    margin-left: 0px;
  }
  .mid-padding {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1370px) {
  .title-tag {
    margin-left: 10px;
  }
  .mid-padding {
    margin-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .location-details {
    padding: 10px;
  }
  .location-info {
    padding-left: 10px;
  }

  .flag-container {
    margin-right: 0;
  }

  .flag-image {
    width: 20px;
  }

  .location-details i {
    font-size: 14px;
  }

  .location-container.active .caret-icon {
    transform: none;
  }
  .hardware-card-container {
    flex-direction: column;
  }
  .pillbox {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 50px;
    color: #fff;
    font-weight: bold;
    font-size: small;
  }
  .title-tag {
    margin-left: 0px;
  }
  .hardware-card {
    width: auto;
    max-width: none;
    height: auto;
    border: 2px solid #1a1c1e;
    border-radius: 10px;
    padding: 30px;
    box-sizing: border-box;
    text-align: left;
    transition: 0.3s all ease-in-out;
    margin-right: 20px;
    margin-top: 20px;
  }

  .tags {
    flex-direction: column;
    align-items: start;
    margin: 10px 0;
  }
}
